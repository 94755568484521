<template>
  <Header />
  <HomeTwoSlider />
  <HomeTwoServices />
  <HomeTwoAbout />
  <!--<HomeTwoFaq />
  <Team />
  <Testimonials />-->
  <Platform />
  <!--<Cta />
 <HomeTwoBlog />-->
  <HomeTwoFooter />
</template>

<script>
import Header from "../../components/Home/Header.vue";
import HomeTwoSlider from "../../components/Home/Slider.vue";
import HomeTwoServices from "../../components/Home/Services.vue";
import HomeTwoAbout from "../../components/Home/About.vue";
//import HomeTwoFaq from "../../components/Home/Faq.vue";
//import Team from "../../components/Home/Team.vue";
//import Testimonials from "../../components/Home/Testimonials.vue";
import Platform from "../../components/Home/Platform.vue";
//import Cta from "../../components/Home/Cta.vue";
//import HomeTwoBlog from "../../components/Home/Blog.vue";
import HomeTwoFooter from "../../components/Home/Footer.vue";

export default {
  name: "HomeTwo",
  components: {
    Header,
    HomeTwoSlider,
    HomeTwoServices,
    HomeTwoAbout,
    // HomeTwoFaq,
    // Team,
    // Testimonials,
    Platform,
    //  Cta,
    //HomeTwoBlog,
    HomeTwoFooter,
  },
};
</script>