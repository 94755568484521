<template>
  <div class="about-area pt-120 pb-90">
    <div class="container">
      <div class="row">
        <div class="col-xl-5 col-lg-6">
          <div class="ab-box pl-50 mb-30">
            <div class="sec-wrapper">
              <h5>About Us</h5>
              <h2 class="section-title">
                22 Years delivering innovation to support GxP processes.
              </h2>
              <p>
                Created in 2000 in Maastricht, the netherlands, NovaXon has
                always believed in the Digital mobile power long time before the
                iPad was borned.
              </p>
              <p>
                With a complete redesign of its applicationn for the version
                3.0, NovaXon is relauching itself and inspire to become a major
                player in the field
              </p>
            </div>
            <div class="abs-item-box mt-40 mb-30">
              <div class="row">
                <div class="col-6">
                  <div class="abs-items mb-20">
                    <div class="abs-icon mb-15">
                      <i class="icon_ribbon_alt"></i>
                    </div>
                    <div class="abs-item-text fix">
                      <h3 class="abs-item-title">Innovation is our genetics</h3>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="abs-items mb-20">
                    <div class="abs-icon mb-15">
                      <i class="icon_lightbulb_alt"></i>
                    </div>
                    <div class="abs-item-text fix">
                      <h3 class="abs-item-title">Audited Company</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ab-btn">
              <router-link to="/platform" class="tp-btn"
                >Learn More</router-link
              >
            </div>
          </div>
        </div>
        <div class="col-xl-7 col-lg-6">
          <div class="abs-images abs-images-2 pl-50">
            <div class="row">
              <div class="col-7">
                <div class="abs-img img-filter mb-30">
                  <img
                    src="../../assets/img/about/FromVisionToReality.gif"
                    alt=""
                    height="400"
                  />
                </div>
                <div class="ab-line-shape w-100 mb-20"></div>
                <div class="ab-line-shape w-50"></div>
              </div>
              <div class="col-5">
                <div class="abs-img img-filter mb-30">
                  <img src="../../assets/img/about/pontDesFees.jpg" alt="" />
                </div>
                <div class="abs-img img-filter mb-30">
                  <img
                    src="../../assets/img/about/MaastrichtBridge.jpeg"
                    alt=""
                  />
                </div>
                <div class="abs-img img-filter mb-30">
                  <img src="../../assets/img/about/BaselBridge.jpeg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeAbout",
};
</script>